import React, { useEffect, useState } from "react";

//Import Components
import Navbar from "./Navbar/Navbar";
import Section from "./HeroSection/Section";
import AboutUs from "./AboutUs/about-us";
import Features from "./Features/features";
import Footer from "./Footer/footer";
import PromotionalSlider from "./PromotionalSlider";

const Home = (props) => {
  const [imglight, setimglight] = useState(true);
  const [navClass, setnavClass] = useState("");

  // Use ComponentDidMount
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });

  const serviceData = {
    title: "Our Services",
    subtitle: "We offer end to end solution to your interior needs",
    items: [
      {
        id: 1,
        title: "Residential Design",
        desc: "Be it the timeless allure of traditional, the captivating blend of eclectic, or the understated yet ample charm of simple residential designs—whatever your inclination may be.",
        features: [],
      },
      {
        id: 2,
        title: "Commercial Design",
        desc: "We boast a seasoned team poised to meticulously orchestrate and oversee the development of commercial properties.",
        features: [],
      },
      {
        id: 3,
        title: "Renovation & Remodelling",
        desc: "With seasoned expertise and a steadfast commitment to excellence, we specialize in the restoration and enhancement of living spaces through renovation and remodeling.",
        features: [],
      },
    ],
  };

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 80) {
      setimglight(false);
      setnavClass("nav-sticky");
    } else {
      setimglight(true);
      setnavClass("");
    }
  }

  return (
    <React.Fragment>
      {/* import navbar */}
      <Navbar navClass={navClass} imglight={imglight} />

      {/* Hero section */}
      <Section />
      <PromotionalSlider />

      {/* mini cards */}
      {/* <CardsMini/> */}

      {/* aboutus */}
      <AboutUs />

      {/* Services */}
      <Features data={serviceData} />

      {/* roadmap */}
      {/* <RoadMap/>    */}

      {/* our team */}
      {/* <OurTeam/> */}

      {/* blog */}
      {/* <Blog/> */}

      {/* faqs */}
      {/* <FAQs/> */}

      {/* footer */}
      <Footer />
    </React.Fragment>
  );
};

export default Home;
