import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import { animated, useSpring } from "react-spring";
import { useScroll } from "react-use-gesture";

import LivingRoom1 from '../../assets/images/hive/lr1.jpg';
import LivingRoom2 from '../../assets/images/hive/lr2.jpg';
import LivingRoom3 from '../../assets/images/hive/lr3.jpg';
import LivingRoom4 from '../../assets/images/hive/lr4.jpg';
import LivingRoom5 from '../../assets/images/hive/lr5.jpg';
import LivingRoom6 from '../../assets/images/hive/lr6.jpg';
import LivingRoom7 from '../../assets/images/hive/lr7.jpg';

import BedroomRoom1 from '../../assets/images/hive/br1.jpg';
import BedroomRoom2 from '../../assets/images/hive/br2.jpg';
import BedroomRoom3 from '../../assets/images/hive/br3.jpg';
import BedroomRoom4 from '../../assets/images/hive/br4.jpg';
import BedroomRoom5 from '../../assets/images/hive/br5.jpg';
import BedroomRoom6 from '../../assets/images/hive/br6.jpg';

const items = [
  {
    src: BedroomRoom3,
    altText: 'Slide 1',
    caption: 'Slide 1',
    key: 10,
  },
  {
    src: LivingRoom6,
    altText: 'Slide 6',
    caption: 'Slide 6',
    key: 6,
  },
  {
    src: BedroomRoom5,
    altText: 'Slide 1',
    caption: 'Slide 1',
    key: 12,
  },
  {
    src: LivingRoom1,
    altText: 'Slide 1',
    caption: 'Slide 1',
    key: 1,
  },
  {
    src: BedroomRoom1,
    altText: 'Slide 1',
    caption: 'Slide 1',
    key: 8,
  },
  {
    src: LivingRoom2,
    altText: 'Slide 2',
    caption: 'Slide 2',
    key: 2,
  },
  {
    src: LivingRoom4,
    altText: 'Slide 4',
    caption: 'Slide 4',
    key: 4,
  },
  {
    src: BedroomRoom2,
    altText: 'Slide 1',
    caption: 'Slide 1',
    key: 9,
  },
  {
    src: LivingRoom3,
    altText: 'Slide 3',
    caption: 'Slide 3',
    key: 3,
  },
  {
    src: BedroomRoom6,
    altText: 'Slide 1',
    caption: 'Slide 1',
    key: 13,
  },
  {
    src: LivingRoom5,
    altText: 'Slide 5',
    caption: 'Slide 5',
    key: 5,
  },
  {
    src: LivingRoom7,
    altText: 'Slide 7',
    caption: 'Slide 7',
    key: 7,
  },
  {
    src: BedroomRoom4,
    altText: 'Slide 1',
    caption: 'Slide 1',
    key: 11,
  },
];

const clamp = (value, clampAt = 30) => {
  if (value > 0) {
    return value > clampAt ? clampAt : value;
  } else {
    return value < -clampAt ? -clampAt : value;
  }
};

const PromotionalSlider = (props) => {

  const [style, set] = useSpring(() => ({
    transform: "perspective(500px) rotateY(0deg)"
  }));

  const bind = useScroll(event => {
    set({
      transform: `perspective(500px) rotateY(${
        event.scrolling ? clamp(event.delta[0]) : 0
      }deg)`
    });
  });

  return (
    <>
      <section className="section pt-4 bg-white pb-0" id="home_interiors">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center">
                <div className="small-title h3">Home Interiors</div>
              </div>
            </Col>
          </Row>
        <div className="animated-container" {...bind()}>
        {items.map(item => (
          <animated.div
            key={item.src}
            className="card"
            style={{
              ...style,
              backgroundImage: `url(${item.src})`
            }}
          />
        ))}
      </div>
        </Container>
      </section>
    </>
  );
}

export default PromotionalSlider;