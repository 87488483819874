import React, { useState } from "react";
import {
  Navbar,
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
  Row,
  Col,
} from "reactstrap";
import ScrollspyNav from "./scrollSpy";

//Import Images
import logodark from "../../../assets/images/logo-dark.svg";
import logolight from "../../../assets/images/logo-light.svg";

const navItems = [
  { id: 1, idnm: "home", navheading: "Home" },
  { id: 2, idnm: "about", navheading: "Our Company" },
  // { id: 3 , idnm : "faqs", navheading: "FAQs" },
];

const Navbar_Page = (props) => {
  const [isOpenMenu, setisOpenMenu] = useState(false);

  //Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
  let TargetId = navItems.map((item) => {
    return item.idnm;
  });

  return (
    <React.Fragment>
      <Navbar
        expand="lg"
        fixed="top"
        className={`navigation sticky ${props.navClass} ${
          isOpenMenu ? "active" : "inactive"
        }`}
      >
        <Container>
          <Row className="w-100 m-0 header-row">
            <Col className="d-flex align-items-center">
              <NavbarBrand className="navbar-logo" href="/">
                {props.imglight && !isOpenMenu ? (
                  <img
                    src={logolight}
                    alt=""
                    height="40"
                    className="logo logo-light"
                  />
                ) : (
                  <img
                    src={logodark}
                    alt=""
                    height="40"
                    className="logo logo-dark"
                  />
                )}
              </NavbarBrand>
            </Col>

            <Col className="justify-content-end d-flex hamburger-menu">
              <NavbarToggler
                className="p-0"
                onClick={() => {
                  setisOpenMenu(!isOpenMenu);
                }}
              >
                <i
                  className={`fa fa-fw fa-bars ${
                    props.imglight && !isOpenMenu ? "text-light" : "text-dark"
                  } `}
                />
              </NavbarToggler>
            </Col>
          </Row>
          <Collapse id="topnav-menu-content" isOpen={isOpenMenu} navbar>
            <ScrollspyNav
              scrollTargetIds={TargetId}
              scrollDuration="1500"
              headerBackground="true"
              activeNavClass="active"
              className="navbar-collapse"
            >
              <Nav className="ml-auto navbar-nav" id="topnav-menu">
                {navItems.map((item, key) => (
                  <NavItem
                    key={key}
                    className={item.navheading === "Home" ? "active" : ""}
                  >
                    <NavLink href={"#" + item.idnm}> {item.navheading}</NavLink>
                  </NavItem>
                ))}
              </Nav>
            </ScrollspyNav>
          </Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};

export default Navbar_Page;
