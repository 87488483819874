import React from "react";
import { Row, Col } from "reactstrap";

//Import Images
import logodark from "../../../assets/images/logo-dark.svg";

const FooterLink = (props) => {
  return (
    <React.Fragment>
      <Row>
        <Col lg="6">
          <div className="mb-4">
            <img src={logodark} alt="" height="20" />
          </div>

          <p className="mb-2">
            {new Date().getFullYear()} © IntoHive. Crafted with ❤️ by TonysHive
          </p>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default FooterLink;
