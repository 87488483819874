import React from "react";
import { Container, Row, Col } from "reactstrap";

//Import Components
import FeatureBox from "./feature-box";

//Import images
import feature1 from "../../../assets/images/crypto/features-img/img-1.png";
import feature2 from "../../../assets/images/crypto/features-img/img-2.png";

const Features = (props) => {
  const { data } = props;

  //   const features1 = [
  //     { id: 1, desc: "Donec pede justo vel aliquet" },
  //     { id: 2, desc: "Aenean et nisl sagittis" },
  //   ];

  const formatFeatureNumber = (value) => value.toString().padStart(2, "0");

  return (
    <React.Fragment>
      <section className="section" id="features">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">{data.title}</div>
                <h4>{data.subtitle}</h4>
              </div>
            </Col>
          </Row>

          {data.items.map((item, index) => {
            const { id, title, desc, features } = item;
            if (id % 2 !== 0) {
              return (
                <Row
                  className="align-items-center py-md-2"
                  key={`services${index}`}
                >
                  <Col md="6" sm="8">
                    <div>
                      <img
                        src={feature1}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                  <Col md="5" className="ml-auto">
                    {/* featurebox */}
                    <FeatureBox
                      num={formatFeatureNumber(id)}
                      title={title}
                      features={features}
                      desc={desc}
                    />
                  </Col>
                </Row>
              );
            }
            return (
              <Row
                className="align-items-center py-md-2"
                key={`services${index}`}
              >
                <Col md="5" className="ml-auto">
                  {/* featurebox */}
                  <FeatureBox
                    num={formatFeatureNumber(id)}
                    title={title}
                    features={features}
                    desc={desc}
                  />
                </Col>
                <Col md="6" sm="8">
                  <div>
                    <img
                      src={feature2}
                      alt=""
                      className="img-fluid mx-auto d-block"
                    />
                  </div>
                </Col>
              </Row>
            );
          })}
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Features;
