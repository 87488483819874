import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const Section = (props) => {
  return (
    <React.Fragment>
      <section className="section hero-section bg-ico-hero" id="home">
        <div className="bg-overlay bg-primary"></div>
        <Container>
          <Row className="align-items-center">
            <Col lg="5">
              <div className="text-white-50">
                <h1 className="text-white font-weight-semibold mb-3 hero-title">
                  IntoHive - Designs as unique as you
                </h1>
                <p className="font-size-14">
                  Move to your dream home without any tension
                </p>

                <div className="button-items mt-4">
                  <Link to="#" className="btn btn-success mr-1">
                    Get Free Consultation
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Section;
