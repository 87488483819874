import React from 'react';
import { Container, Row, Col } from "reactstrap";

const AboutUs = (props) => {

    return (
      <React.Fragment>
                <section className="section pt-4 bg-white" id="about">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className="text-center mb-5">
                                    <div className="small-title">Our Company</div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
        
                                <div className="text-muted">
                                    <h5 className='text-center'>At Intohive we believe that the spaces we inhabit should not only be aesthetically pleasing but should also tell a unique story. As a premier interior design company, we are dedicated to transforming environments into personalized works of art that harmonize functionality with style. Established with a passion for creating captivating interiors, Intohive brings a fresh perspective to the world of design. Our commitment goes beyond the mere arrangement of furniture and colors. We strive to understand the essence of our customer's lifestyles and aspirations, translating them into bespoke designs that resonate with individuality.</h5>
                                </div>

                        </Row>
                    </Container>
                    
                </section>
            </React.Fragment>     
          );
    }
        
export default AboutUs;