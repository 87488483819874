import React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  UncontrolledDropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledTooltip
} from "reactstrap";

const ContactsList = (props) => {
  const users = [
    {
			created_at: "2022-12-08T06:04:08.807Z",
			designation: "IntoHive Admin",
			email: "admin@intohive.in",
			first_name: "Admin",
			id: "5694d130-e5e3-4b57-a95e-a18f12baaf3e",
			is_email_verified: false,
			is_mobile_number_verified: false,
			last_name: "User",
			mobile_number: "7904929522",
			profile_image: "test",
			role: {
				name: 'super_admin',
				label: 'Super Admin'
			},
			status: 1,
			subscription: {
				name: 'basic',
				label: 'Basic'
			},
			updated_at: "2022-12-08T06:04:08.807Z"
    },
		{
			created_at: "2022-12-08T06:04:08.807Z",
			designation: "Chief Executive Officer",
			email: "manikandan.r@intohive.in",
			first_name: "Manikandan",
			id: "5694d130-e5e3-4b57-a95e-a18f12baaf3f",
			is_email_verified: false,
			is_mobile_number_verified: false,
			last_name: "Ramakrishnan",
			mobile_number: "7904929522",
			profile_image: "test",
			role: {
				name: 'admin',
				label: 'Admin'
			},
			status: 1,
			subscription: {
				name: 'premium',
				label: 'Premium'
			},
			updated_at: "2022-12-08T06:04:08.807Z"
    },
  ];

	const getSubscription = (subscription) => {
		switch (subscription.name) {
			case 'basic':
				return <span className="badge badge-pill badge-warning">{subscription.label}</span>
			case 'premium':
				return <span className="badge badge-pill badge-info">{subscription.label}</span>
			default:
				break;
		}
	}

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table-centered table-nowrap table-hover">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Mobile</th>
													<th scope="col">Role</th>
                          <th scope="col">Subscription</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((user, i) => (
                          <tr key={"_user_" + i}>
                            <td>
                              {!user.img ? (
                                <div className="avatar-xs">
                                  <span className="avatar-title rounded-circle">
                                    {user.first_name.charAt(0)}{user.last_name.charAt(0)}
                                  </span>
                                </div>
                              ) : (
                                <div>
                                  <img
                                    className="rounded-circle avatar-xs"
                                    src={user.profile_image}
                                    alt=""
                                  />
                                </div>
                              )}
                            </td>
                            <td>
                              <h5 className="font-size-14 mb-1">
                                <Link to="#" className="text-dark">
																	{user.first_name}  {user.last_name}
                                </Link>
                              </h5>
                              <p className="text-muted mb-0">
                                {user.designation}
                              </p>
                            </td>
                            <td><span>{user.email}</span> {
																	!user.is_email_verified &&
																	<Link to="#" id={"email" + user.id}>
																		<i className="mdi mdi-alert-octagon text-danger h4 m-0"></i>
																		<UncontrolledTooltip placement="top" target={"email" + user.id}>
																			Email not verified
																		</UncontrolledTooltip>
																	</Link>
																}
														</td>
														<td><span>{user.mobile_number}</span> {
																	!user.is_mobile_number_verified &&
																	<Link to="#" id={"mobile" + user.id}>
																		<i className="mdi mdi-alert-octagon text-danger h4 m-0"></i>
																		<UncontrolledTooltip placement="top" target={"mobile" + user.id}>
																			Mobile Number not verified
																		</UncontrolledTooltip>
																	</Link>
																}
														</td>
														<td>{user.role.label}</td>
														<td>{getSubscription(user.subscription)}</td>

                            <td>
                              <ul className="list-inline font-size-20 contact-links mb-0">
																<Link to="#" id={"profile" + user.id}>
																	<UncontrolledDropdown>
																		<DropdownToggle href="#" className="arrow-none" tag="i">
																			<i className="mdi mdi-dots-horizontal text-dark h4 m-0"></i>
																		</DropdownToggle>
																		<DropdownMenu up="true">
																			<DropdownItem href="#">Edit</DropdownItem>
																			<DropdownItem href="#">Delete</DropdownItem>
																			<DropdownItem href="#">Block</DropdownItem>
																		</DropdownMenu>
																	</UncontrolledDropdown>
																</Link>
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ContactsList;
