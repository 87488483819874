import React from "react";
import { Container, Row, Col, NavLink } from "reactstrap";

//Import Components
import FooterLink from "./footer-link";

const Features = (props) => {
  const footerLinks = [
    {
      title: "Company",
      links: [
        { title: "Our Company", link: "#" },
        { title: "Privacy Policy", link: "#" },
        { title: "Terms & Conditions", link: "#" },
      ],
    },
  ];

  return (
    <React.Fragment>
      <footer className="landing-footer">
        <Container>
          <Row>
            {footerLinks.map((footerLink, key) => (
              <Col lg="8" sm="6" key={key}>
                <div className="mb-4 mb-lg-0">
                  <h5 className="mb-3 footer-list-title">{footerLink.title}</h5>
                  <ul className="list-unstyled footer-list-menu">
                    {footerLink.links.map((Flink, key) => (
                      <li key={key}>
                        <NavLink className="p-0" href={`#${Flink.link}`}>
                          {Flink.title}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            ))}

            <Col lg="4" sm="6">
              <div className="mb-4 mb-lg-0">
                <h5 className="mb-3 footer-list-title">Locations</h5>
                <ul className="list-unstyled footer-list-menu">
                  <li>
                    <i className="bx bx-current-location mr-1 text-danger"></i>
                    &nbsp;
                    <span>
                      S2, Vibrant Apartment, Sowmiya Nagar, 10th Street,
                      Perumbakam, Chennai - 600100, TN.
                    </span>
                  </li>
                  <li>
                    <i className="bx bx-current-location mr-1 text-danger"></i>
                    &nbsp;
                    <span>
                      Plot No.31, NPS Anbu Nagar, Chinniyampalayam, Erode -
                      638151, TN.
                    </span>
                  </li>
                </ul>
              </div>
              <div className="mb-4 mb-lg-0">
                <h5 className="mb-3 footer-list-title">Support</h5>
                <ul className="list-unstyled footer-list-menu">
                  <li>
                    <i className="mdi mdi-at mr-1 text-danger"></i>&nbsp;
                    <span>hello@intohive.in</span>
                  </li>

                  <li>
                    <i className="mdi mdi-phone-outline mr-1 text-danger"></i>
                    &nbsp;<span>+91 - 789 789 5027</span>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

          <hr className="footer-border my-5" />

          <FooterLink />
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Features;
